.gallery-image-container {
    background-color: $dark-gray;
    display: flex;
    flex: 1;
    border-radius: 6px;
    padding-bottom: 100%;
    margin-bottom: 30px;
    background-position: center;
    background-size: cover;
    transition: .2s;
    cursor: pointer;
    aspect-ratio: 1;
}

.gallery-image-container > video {
    border-radius: 6px;
    object-fit: cover;
}

.gallery-image-container:hover {
    box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.5);
}

.carousel-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.carousel-header-title {
    font-size: 16px;
    font-weight: $font-weight-semi;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.carousel-header-button {
    margin: 0;
    padding: 4px 8px;
}

.gallery-img-container {
    width: 100%;
    margin: auto;
    background-color: $medium-gray;
    border-radius: 6px;
    color: $dark-gray;
    cursor: pointer;
    transition: 0.2s;
}

.gallery-img-container:hover {
    background-color: $dark-gray;
    color: #000000;
}

.gallery-img-icon-container {
    display: flex;
    justify-content: center;
    min-height: 200px;
    align-items: center;
}

.gallery-empty-container {
    display: flex;
    height: calc(100vh - 30px - 135px);
    height: calc(var(--vh, 1vh) * 100 - 30px - 135px);
}