.rc-slider-primary .rc-slider-handle {
    border-color: $primary-color;
}

.rc-slider-primary .rc-slider-handle:hover {
    border-color: $primary-color;
}

.rc-slider-primary .rc-slider-handle:active {
    box-shadow: 0 0 5px $primary-color;
    border-color: $primary-color;
}

.rc-slider-primary .rc-slider-handle-click-focused:focus {
    border-color: $primary-color;
}

.rc-slider-primary .rc-slider-track {
    background-color: $primary-color-alert;
}

.rc-slider-primary .rc-slider-dot-active {
    border-color: $success-color;
}