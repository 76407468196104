body {
  color: $black-color;
  font-size: $font-size-base;
  font-family: $sans-serif-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  background: $white-color;
}
/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
  @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
  @include rotate-180();
}

.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: $white-bg;

  & + .button-bar {
    margin-top: 7px;
  }

  &:nth-child(2) {
    width: 17px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
}

.under-construction-animation {
  height: 250px;
  width: 250px;
}

.under-construction-title {
  font-weight: $font-weight-semi;
  font-size: 2em;
  padding: 10px 0px;
  text-align: center;
}

.under-construction-description {
  text-align: center;
}

@media screen and (max-width: 420px) {
  .under-construction-animation {
    height: 150px;
    width: 150px;
  }
  .under-construction-title {
    font-size: 1.5em;
  }
  .under-construction-description {
    font-size: 0.9em;
  }
}