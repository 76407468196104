.card-to-do {
    @extend %card-stats;

    .card-body {
        padding-bottom: 0px;
    }

    .hr {
        margin-top: 0px!important;
        margin-bottom: 1rem!important;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        height: 10px;
        width: 8px;
      }

    ::-webkit-scrollbar-thumb {
        background: rgb(196, 196, 196);
        border-radius: 10px;
    }

    .to-do-empty-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-bottom: 10px;
    }

    @media (min-width: 992px) {
        .card-body {
            max-height: 259px;
            height: 259px;
            overflow-y: auto;
        }
    }
}