.calendar-card-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    //transform: translate(-50%, -50%);
    z-index: 1000;
}

.calendar-card-spinner-container {
    left: 18px;
    width: calc(100% - 36px);
    height: 100%;
    position: absolute;
    background-color: rgba(209, 209, 209, 0.3);
    z-index: 1000;


}

@media (max-width: 707px) {
    .calendar-card-spinner-container {
        width: unset;
        height: 100%;
        left: 0;
        right: 0;
    }
}

.calendar-dropdown-image {
    height: 25px;
    width: 25px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 12px;
}

.calendar-dropdown-label {
    display: inline-block;
    padding-right: 1.5rem;
    min-width: 170px;
}

.calendar-dropdown-check {
    display: inline-block;
    padding-right: 1.5rem;
}

.calendar-resource-dropdown-image {
    height: 25px;
    width: 25px;
    overflow: hidden;
    border-radius: 50%;
}

.calendar-resource-dropdown {
    max-height: 40px;
}

.calendar-settings-modal-body {
    text-align: left;
}

.calendar-settings-modal-body label {
    font-weight: 600;
}

.calendar-event-container {
    text-align: left;
    overflow: hidden;
    color: #000;
}

.calendar-booking-times {
    color: $dark-gray;
    font-size: 11px;
}

.calendar-booking-title {
    font-size: 13px;
    font-weight: 600;
}

.calendar-booking-service {
    font-size: 12px;
}

.calendar-booking-recurring {
    position: absolute;
    top: 5px;
    right: 5px;
}

.fc-timegrid-event .fc-event-main {
    overflow: hidden;
}

.fc-timegrid-event-condensed > .fc-event-main > .calendar-event-container {
    display: flex;
}

.fc-timegrid-event-condensed > .fc-event-main > .calendar-event-container > .calendar-booking-title {
    margin-left: 5px;
}

.fc-timegrid-event-condensed > .fc-event-main > .calendar-event-container > .calendar-booking-service {
    display: none;
}

.calendar-mock-container {
    border: 1px solid var(--fc-border-color, #ddd);
}

.calendar-no-resource-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.calendar-no-resource-icon {
    margin-bottom: 10px;
}

.calendar-no-resource-title {
    margin-bottom: 10px;
    font-size: 14px;
}

.booking-options-modal-header {
    padding: 0px!important;
}

.booking-options-modal-header > .modal-title > .image {
    height: 90px;
    overflow: hidden;
}

.booking-options-modal-header > .modal-title > .author {
    margin-top: -54px;
}

.booking-options-modal-header > .modal-title > .author > .customer-profile-avatar {
    height: 100px;
    width: 100px;
}

.booking-options-modal-header > .modal-title > .author > .customer-profile-avatar > .customer-profile-avatar-initials {
    line-height: 100px;
}

.booking-options-modal-header-title {
    font-size: 1.57rem;
    font-weight: 700;
}

.booking-options-modal-header-description {
    font-size: 1.14rem!important;
    font-weight: 400;
    margin-bottom: 0px;
}

.calendar-options-modal-body {
    padding: 16px 24px 5px 24px!important;
}

.tooltip { z-index: 1151 !important; }

.booking-options-modal-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.booking-options-modal-service-description {
    font-size: 12px;
    line-height: 1.5;
}