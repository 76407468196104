.toastify-primary {
    background-color: $primary-color!important;
    color: $white-color!important;
}

.toastify-primary .Toastify__close-button {
    color: $white-color;
}

.toastify-success-progress {
    background: linear-gradient(darken($success-color, 5%), $success-color)!important;
}

.toastify-warning-progress {
    background: linear-gradient(darken($warning-color, 5%), $warning-color)!important;
}

.toastify-danger-progress {
    background: linear-gradient(darken($danger-color, 5%), $danger-color)!important;
}