.customer-result-container {
    margin-top: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.customer-row {
    transition: .1s;
}

.customer-row:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.customer-circle {
    height: 46px;
    width: 46px;
    background-color: #0a122b;
    border-radius: 23px;
    line-height: 46px;
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    display: inline-block;
}

.customer-name-container {
    margin-left: 15px;
    display: inline-block;
}

.customer-name {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
}

.customer-result-container > .table-responsive {
    padding-bottom: 0px;
}

.customer-info-mini {
    font-size: 10px;
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customer-info {
    font-size: 14px;
}

.customer-no-results {
    margin: 10px 0px;
    font-size: 16px;
}

@media only screen and (max-width: 767px) {
    .customer-info {
        display: none;
    }
    .customer-info-mini {
        display: block;
    }
    .customer-name {
        font-size: 15px;
    }
}

@media only screen and (max-width: 435px) {
    .nav-pills.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
        margin-right: 5px;
    }
    .nav-pills .nav-item .nav-link {
        padding: 11px;
    }
}

// Customer profile

.customer-profile-info-container {
    margin: 12px 0px;
}

.customer-profile-info {
    font-size: 0.85rem;
}

.customer-profile-info-bold {
    font-weight: 600;
    margin-bottom: 5px;
}

.customer-profile-avatar {
    margin-left: auto;
    margin-right: auto;
    height: 124px;
    width: 124px;
    position: relative;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background-color: $primary-color;
    margin-bottom: 15px;
}

.customer-profile-avatar-initials {
    line-height: 124px;
    color: #ffffff;
    font-weight: 300;
    font-size: 2.4rem;
}

.customer-profile-buttons {
    text-align: center;
}

.customer-profile-button {
    border-radius: 19px;
}

.customer-profile-pill {
    flex: 1;
    cursor: pointer;
}

.customer-profile-booking-container {
    width: 100%;
    border: 1px solid $medium-gray;
    border-left-width: 8px;
    border-left-color: #1ab394;
    border-radius: 6px;
    margin-bottom: 1rem;
    padding: 12px;
    box-shadow: $box-shadow;
    cursor: pointer;
}

.customer-profile-booking-info {
    margin-bottom: 12px;
}

.customer-profile-booking-service-container {
    margin-bottom: 10px;
}

.customer-profile-booking-service-info {
    color: $dark-gray;
    font-size: 12px;
}

.customer-profile-booking-total {
    font-weight: 600;
}

.customer-profile-booking-cancelled {
    font-weight: 700;
    color: $danger-color;
    display: inline-block;
}

.customer-profile-stat-title {
    margin-bottom: 10px;
}