.form-control[readonly] {
    background-color: transparent;
    cursor: pointer;
}

.time-group {
    display: flex;
}

.time-input {
    flex: 1
}

.time-input-left > .react-select__control {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}

.time-input-right > .react-select__control {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}

.date-group {
    display: flex;
}

.date-input {
    flex: 1
}

.date-input-left input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.date-input-right input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.date-input-right > .rdtPicker {
    right: 0;
}

.date-input-right > .rdtPicker:before {
    right: 10px;
    left: unset;
}

.date-input-right > .rdtPicker:after {
    right: 10px;
    left: unset;
}

.staff-select-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 8px;
}