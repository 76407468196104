.card-staff-status {
    @extend %card-stats;

    .card-body {
        padding-bottom: 0px;
    }

    .staff-row {
        height: 70px;
        white-space: nowrap;
        overflow: hidden;
    }

    .staff-cell-name {
        font-weight: 600;
    }

    .hr {
        margin-top: 0px!important;
        margin-bottom: 1rem!important;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .img-wrapper {
        width: 45px;
        height: 45px;
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        height: 10px;
        width: 8px;
      }

    ::-webkit-scrollbar-thumb {
        background: rgb(196, 196, 196);
        border-radius: 10px;
    }

    .staff-status-empty-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-bottom: 10px;
    }

    @media (min-width: 992px) {
        .card-body {
            max-height: 268px;
            height: 268px;
            overflow-y: auto;
        }
    }
    @media (max-width: 576px) {
        .staff-cell-status {
            display: none;
        }
    }
}