.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide img {
    display: block;
    //max-height: 400px;
}

.swiper-button-prev {
    color: #1ab394!important;
}

.swiper-button-next {
    color: #1ab394!important;
}

.swiper-pagination-bullet-active {
    background: #1ab394!important;
}

.slide-caption {
    font-weight: 600;
    margin-bottom: 30px;
}