.referral-instruction-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.referral-prize-animation-container {
    width: 100%;
}

.referral-code-title {
    font-size: 2em;
    font-weight: 600;
}

.referral-code {
    font-size: 2em;
    background-color: $light-gray;
    border-radius: $border-radius-small;
    padding: 0px 15px;
    margin-top: 5px;
    color: $dark-gray;
    cursor: pointer;
    transition: .2s;
}

.referral-code:hover {
    color: $black-color;
}