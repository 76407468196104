.react-tel-input .form-control {
    padding: 10px 18px 10px 60px!important;
    font-size: 14px!important;
    width: 100%!important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
    border-color: #e3e3e3!important;
}

.react-tel-input .form-control:focus {
    border-color: $primary-color!important;
    box-shadow: none!important;
}

.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
    border-color: $primary-color!important;
    box-shadow: none!important;
}

.react-tel-input .selected-flag:before {
    top: 0px!important;
    bottom: 0px!important;
}

.react-tel-input :disabled {
    background-color: #e3e3e3!important;
    color: #888!important;
}

.react-tel-input li {
    font-family: 'Public Sans';
}