.business-locations-container {
    display: flex;
    flex-direction: row;
    background-color: $white-color;
    margin-top: $height-minimised-navbar;
}

.business-locations-column {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    height: 100%;
    flex: 0.25;
    min-width: 25%;
    height: calc(100vh - #{$height-minimised-navbar});
    z-index: 1;
    box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.3);
    overflow: auto;
}

.business-locations-column-header {
    display: flex;
    flex: 1;
    padding: 15px 15px 15px 15px;
    border-bottom: 1px solid #e3e3e3;
}

.business-locations-map-container {
    position: relative;
    overflow: hidden;
    height: calc(100vh - #{$height-minimised-navbar});
    width: 100%;
}

.business-location-container {
    padding: 20px 15px;
    border-bottom: 1px solid #e3e3e3;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
}

.business-location-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.business-location-description {
    font-size: 12px;
}

.no-locations-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 1350px) {
    .business-locations-column {
        min-width: 35%;
        flex: 0.35;
    }
}

@media screen and (max-width: 1200px) {
    .business-locations-column {
        min-width: 40%;
        flex: 0.4;
    }
}

@media screen and (max-width: 991px) {
    .business-locations-column {
        min-width: 50%;
        flex: 0.5;
    }
}

@media screen and (max-width: 768px) {
    .business-locations-column {
        min-width: 100%;
        flex: 1;
    }
    .business-locations-map-container {
        display: none;
    }
}