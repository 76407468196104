.billing-metric-title {
    margin: 15px 0 5px;
}

.billing-metric-info {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #2c2c2c;
}

.billing-metric-card-info {
    font-size: 22px;
}

.payment-methods-header {
    margin-bottom: 5px;
}

.invoices-header {
    margin-bottom: 20px;
}

.payment-methods-container {
    overflow-x: auto;
    padding-top: 15px;
    padding-bottom: 5px;
    white-space: nowrap;
    margin-bottom: 20px;
}

.payment-method-container {
    display: inline-block;
    margin: 0px 10px;
}

.payment-card-mockup {
    width: 290px;
    height: 182.86px;
    border: 2px solid #2c2c2c;
    border-radius: 14.5px;
}

.payment-card-mockup-button {
    display: flex;
    border-width: 2px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.rccs__card--amex .rccs__card__background {
    background: linear-gradient(25deg, #000000, #545454)
}

.payment-method-input-container {
    display: block;
    margin: 0px;
    padding: 12px;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
}

.payment-method-terms {
    text-align: left;
    font-size: 12px;
    font-family: 'Public Sans';
    font-weight: 500;
    margin-bottom: 15px;
}

.discount-alert {
    margin: 0px 0px 15px 0px !important
}