.fc-col-header-cell-cushion {
  width: 100%;
}

.fc-v-event {
  border: none;
  background-color: #ffffff;
}

/*.fc-event-main {
  border: 1px solid #bfbfbf;
  border-left-width: 5px;
  border-left-color: #1ab394;
  border-left-style: solid;
  border-radius: 3px;
  padding: 5px!important;
  color: #192027!important;
}*/

.fc-event-generic {
  border: 1px solid #bfbfbf;
}

.fc-event-booked {
    border-left: 6px solid #1ab394!important;
}

/*.fc-event-placeholder {
  padding-left: 6px;
}*/

.fc-event-in-progress {
    border-left: 6px solid #f8ac59!important;
}

.fc-event-completed {
    border-left: 6px solid #c4c4c4!important;
}

.fc-event-no-show {
    border-left: 6px solid #ff3046!important;
}

.fc-event-break {
    border-left: 6px solid #aeaeae!important;
}

.fc-timegrid-event {
  z-index: 10;
}

.fc-timegrid-event .fc-event-main {
  padding: 4px 4px 0;
}

.fc-v-event .fc-event-time {
    text-align: left;
}

.fc-v-event .fc-event-title {
  font-weight: 600;
}

.fc-v-event .fc-event-title-container {
  text-align: left;
}

.fc-timegrid-event .fc-event-time {
  font-size: 12px;
}

.fc-event:hover {
  cursor: pointer;
  color: #000000!important;
  background-color: #f5f5f5;
}

.fc-event-left-border-mockup {
  height: 100%;
  position: absolute;
  width: 6px;
  top: 0px;
  left: 0px;
}

.time-tooltip {
  position:absolute;
  border:0px;
  background-color:rgba(0, 0, 0, 0.8);
  border-radius:3px;
  z-index:1;
  color:#ffffff;
  text-align:left;
  padding: 0px 10px;
  pointer-events: none;
}

.fc-list-event {
  width: 40%;
  margin: 5px auto;
}

.fc-daygrid-day-number {
  text-decoration: none;
}

.fc .fc-col-header-cell-cushion {
  text-decoration: none;
}

@media screen and (max-width: 1199px) {
  .fc-list-event {
    width: 50%;
  }
}

@media screen and (max-width: 991px) {
  .fc-list-event {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .fc-list-event {
    width: 70%;
  }
}

@media screen and (max-width: 575px) {
  .fc-list-event {
    width: unset;
    margin: 5px;
  }
}