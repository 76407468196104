.settings-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid $light-gray;
    font-weight: $font-weight-semi;
    font-size: 1.1em;
    padding: 15px;
    transition: .2s;
    cursor: pointer;
}

.settings-row:hover {
    background-color: #f1f1f1;
}

.color-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.color-title {
    font-weight: $font-weight-semi;
}

.action-button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

// Push notifications page

.push-notification-container {
    background-color: #e0e0e0;
    box-shadow: $box-shadow;
    border-radius: $border-radius-extreme;
    padding: 10px;
}

.push-notification-icon {
    background-color: #b7b7b7;
    height: 20px;
    width: 20px;
    border-radius: $border-radius-small;
    margin-right: 5px;
    background-size: cover;
}

.push-notification-title-placeholder {
    width: 30%;
    background-color: #b7b7b7;
    height: 15px;
    margin-top: 10px;
    border-radius: $border-radius-small;
}

.push-notification-title {
    font-weight: $font-weight-semi;
    margin-top: 10px;
    line-height: 15px;
}

.push-notification-message-placeholder {
    background-color: #d0d0d0;
    height: 15px;
    margin-top: 10px;
    border-radius: $border-radius-small;
}

.push-notification-message {
    margin-top: 10px;
    line-height: 15px;
}

.push-notification-list-container {
    max-height: calc(100vh - 180px);
    max-height: calc(var(--vh, 1vh) * 100 - 180px);
    overflow-y: auto;
}

.app-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-status-title {
    font-size: 1.4em;
    margin-left: -5px;
    margin-bottom: 2px;
}

.app-status-progress-timeline-container {
    position: absolute;
    height: 405px;
    left: 5%;
    margin-left: -18px;
    top: 0;
    bottom: 0;
}

.app-status-progress-overlay-1 {
    position: absolute;
    left: 25%;
    margin-top: 5px;
    width: 4px;
    background-color: darken($light-gray, 3%);
    height: 0%;
}

.app-status-progress-overlay-2 {
    position: absolute;
    right: 25%;
    margin-top: 5px;
    width: 4px;
    background-color: darken($light-gray, 3%);
    height: 0%;
}

.app-status-animation-container {
    margin-top: -20px;
    max-width: 560px;
}

@media screen and (max-width: 767px) {
    .app-status-animation-container {
        max-width: unset;
    }
}

.ios-app-icon {
    border-radius: 20%;
    width: 150px;
}

.android-app-icon {
    width: 150px;
}

.app-status-version {
    text-align: center;
}

// Instagram integration screen

.instagram-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.instagram-header-logo-container {
    width: 30px;
    min-width: 30px;
    margin: auto;
}

.instagram-header-handle-container {
    margin-top: 3px;
    margin-left: 10px;
}

@media screen and (max-width: 767px) {
    .instagram-header-handle-container {
        margin: 10px auto;
        margin-left: 0;
    }
    .instagram-header-container {
        display: block;
    }
}

.instagram-gradient {
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

// Business settings screen

.business-brand-area {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    background-color: #dddddd;
    color: #9a9a9a;
    border-radius: $border-radius-large;
    cursor: pointer;
    transition: .2s;
}

.business-brand-area:hover {
    background-color: #cfd0d4;
    color: #000000;
}

.business-brand-logo {
    max-width: 90%;
    max-height: 200px;
}