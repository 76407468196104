.page-container {
    display: flex;
    flex-direction: row;
    background-color: $white-color;
    margin-top: $height-minimised-navbar;
}

.page-column {
    display: flex;
    flex: 1;
    height: calc(100vh - #{$height-minimised-navbar});
}