.card-business {
    @extend %card-stats;
    min-height: 275.39px;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-size: cover;
    background-position: center;

    .card-overlay {
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.6);
      position: absolute;
      z-index: 1;
      border-radius: 0.1875rem;
    }

    .card-body {
      z-index: 2;
    }
  
    .card-description {
      max-width: 350px;
      margin: 0 auto;
      color: #cccccc;
    }

    .card-logo {
      max-width: 270px;
      max-height: 198px;
      margin-bottom: 10px;
    }
  
    .card-title {
      font-size: 50px;
      color: #ffffff;
    }
  
    .card-stats {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 11px;
    }
  
    .card-footer {
      [class*="col-"]:not(:first-child):before {
        content: "";
        position: absolute;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: $hr-line;
      }
    }
  
    .bootstrap-switch {
      margin: 0;
    }
  
    span {
      padding-left: 15px;
      text-align: left;
      max-width: 125px;
    }

    @media (min-width: 1200px) {
        .card-title {
            font-size: 30px;
        } 
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        .card-title {
            font-size: 25px;
        } 
    }
    @media (min-width: 576px) and (max-width: 991px) {
        .card-title {
            font-size: 32px;
        } 
    }
    @media (max-width: 575px) {
        .card-title {
            font-size: 28px;
        } 
    }
} 
.card-business-sm {
  min-height: 220px!important;
}