.btn.btn-outline-neutral {
  &,
  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active {
    border-color: $white-color;
  }
}
.btns-mr-5 .btn {
  margin-right: 5px;
}

@media all and (max-width:480px) {
  .btn-sm-block {
    width: 100%;
    margin-bottom: $margin-bottom!important;
  }
}