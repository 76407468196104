.card-stats-mini {
  &.card-background::after {
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    opacity: 0.94;
  }

  .card-body {
    &::after {
      clear: both;
      content: "";
      display: block;
    }
  }

  .card-footer {
    border-top: 1px solid $opacity-2;
    margin: 0 15px;
  }

  &.card-background {
    .card-body {
      min-height: auto;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .card-title {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .info-area {
    text-align: left;
    width: 40%;
    float: left;
    padding: 15px 0;

    .icon > i {
      font-size: 2em;
    }
  }

  .chart-area {
    float: left;
    width: 60%;
  }

  .stats {
    text-align: left;
    color: $white-color;
  }
}
