.staff-card {
    margin-top: 0;
    transition: .2s;
    cursor: pointer;
}

.staff-card img {
    border-radius: 50%!important;   
}

.staff-card .card-avatar {
    max-width: 130px;
    min-width: 130px;
    max-height: 130px;
    min-height: 130px;
}

.staff-card .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.staff-card .card-body {
    min-height: 329px;
}

.staff-card .card-description {
    font-size: 1rem;
    font-weight: $font-weight-normal;
}

.staff-card:hover {
    box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.3);
}

.staff-img-container {
    height: 130px;
    width: 130px;
    margin: auto;
    background-color: $medium-gray;
    border-radius: 50%;
    color: $dark-gray;
    cursor: pointer;
    transition: 0.2s;
}

.staff-img-container:hover {
    background-color: $dark-gray;
    color: #000000;
}

.staff-img-icon-container {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    //color: $dark-gray;
}

.staff-img-placeholder {
    border-radius: 50%;
}

.staff-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.staff-header-row .nav {
    flex-wrap: unset;
}

.staff-header-button {
    margin: 0;
    color: #ffffff;
    height: 43px;
}

.staff-minified-button {
    display: none;
    margin: 0;
    width: 100%;
}

@media screen and (max-width: 575px) {
    .staff-header-button {
        display: none;
    }
    .staff-minified-button {
        display: block;
    }
}

.staff-empty-container {
    display: flex;
    height: calc(100vh - 43px - 123px);
    height: calc(var(--vh, 1vh) * 100 - 43px - 123px);
}

// Staff Schedules

.staff-schedule-table th, .staff-schedule-table td {
    text-align: center;
    border: 1px solid #dee2e6!important;
}

.staff-cell {
    display: flex;
    flex-direction: row;
    text-align: left!important;
    font-weight: 600;
    white-space: nowrap;
    min-width: 160px;
    cursor: pointer;
    transition: .2s;
}

.staff-cell:hover {
    background-color: $light-gray;
}

.staff-cell img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-right: 7px;
}

@media screen and (max-width: 575px) {
    .staff-cell img {
        display: none;
    }
    .staff-cell {
        min-width: 120px;
    }
}

.staff-cell-info-container {
    flex-direction: column;
    align-self: center;
}

.staff-cell-schedule-label {
    font-size: $font-size-mini;
}

/*.staff-cell div {
    display: inline-block;
}*/

.time-cell {
    min-width: 103px;
    cursor: pointer;
    transition: .2s;
}

.closed-cell {
    background-color: $dark-gray!important;
    color: $opacity-gray-5;
    font-weight: $font-weight-semi;
}

.closed-cell:hover {
    color: #ffffff;
}

.amended-cell {
    background-color: $warning-color-alert!important;
    color: $black-color;
}

.amended-cell:hover {
    background-color: lighten($warning-color-alert, 10%)!important;
}

.holiday-cell {
    background-color: $info-color-alert!important;
    color: $white-color;
    font-weight: $font-weight-semi;
}

.holiday-cell:hover {
    background-color: lighten($info-color-alert, 10%)!important;
}

.normal-cell:hover {
    background-color: $light-gray;
}

.not-working-cell {
    background-color: $medium-gray!important;
    color: $dark-gray;
    font-weight: $font-weight-semi;
}

.not-working-cell:hover {
    background-color: $light-gray!important;
}

@media screen and (max-width: 1100px) {
    .not-working-cell {
        font-size: 12px;
    }
    .closed-cell {
        font-size: 12px;
    }
}

/* Staff schedule key */

.schedule-key-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.schedule-key-box {
    height: 26px;
    width: 26px;
    border-radius: 3px;
    border: 1px solid #dee2e6;
}

/* Staff schedule options */

.schedule-option-info-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
}

.schedule-option-info-title {
    font-weight: $font-weight-semi;
}

.schedule-day-name-title {
    display: inline-flex;
    font-size: 18px;
}

.schedule-day-switch {
    display: inline-flex;
    float: right;
    margin-top: 5px;
}

/* Staff schedules modal */

.schedule-category-header {
    a {
        color: $white-color!important;
        padding: 0px 10px;
    }
    i {
        color: $white-color!important;
        top: 5px!important;
    }
}

.schedule-category-header-primary {
    background-color: $primary-color!important;
}

.schedule-category-header-conflict {
    background-color: $danger-color!important;
}

.schedule-category-body {
    padding-left: 0!important;
    padding-right: 0!important;
}

.schedule-container {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid $dark-gray;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    font-weight: $font-weight-semi;
}

.schedule-container:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}