// Autocomplete dropdown
.pac-item {
    font-family: 'Public Sans';
    padding: 10px 10px;
}
.pac-icon {
    color: $primary-color;
}
.business-map-pin {
    padding: 10px;
    position: absolute;
    left: -10px;
    top: -10px;
    background-color: #0a122b;
    border-radius: 50%;
    &.hover {
        background-color: $white-color;
        color: $primary-color;
    }
}