/*.cropper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}*/

.cropper-container {
    position: relative;
    width: 100%;
    height: 320px;
    right: 0;
    bottom: 0;
    padding: 20px 0px;
}

.cropper-container-transparent {
    background-color: #9a9a9a;
}

.cropper-control-row {
    display: flex;
    flex-direction: row;
    flex: 1;
}