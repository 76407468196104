.color-picker-swatch-container {
    padding: 3px;
    background-color: $white-bg;
    border-radius: 3px;
    border: 1px solid $primary-color;
    display: inline-block;
    cursor: pointer;
}

.color-picker-swatch {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.rcp-root {
    --rcp-background-color: transparent!important;
    --rcp-field-input-color: #{$primary-color}!important;
    --rcp-field-input-border-color: #{$medium-gray}!important;
    --rcp-field-label-color: #{$medium-gray}!important;
}