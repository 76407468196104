.service-category-container {
    display: flex;
    justify-content: space-between;
}

.service-category-title {
    margin: 0!important;
    font-weight: 700;
}

.service-container {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.service-row-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0px;
}

.service-row-flex-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.service-row-item {
    font-size: 16px;
}

.service-row-title {
    font-weight: 600;
    min-width: 25%;
    max-width: 25%;
}

.service-row-tier-title {
    min-width: 25%;
    max-width: 25%;
}

.service-row-muted {
    color: #9a9a9a;
}

.service-row-title-info {
    margin: auto 0px;
}

.service-row-info {
    white-space: nowrap;
}

.service-row-tier-item {
    display: inline-block;
}

.duration-split-separator {
    border-radius: 0;
    padding: 8px 7px 9px 7px;
}

.tier-staff-img {
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.tier-staff-row {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 10px;
    min-height: 42px;
    align-items: center;
}

.tier-add-staff-container {
    border-color: #0a122b;
    cursor: pointer;
}

.tier-staff-name {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    padding-left: 8px;
    margin: auto 0px;
}

.tier-staff-icon {
    color: $danger-color;
    margin: auto 0px;
    cursor: pointer;
    padding: 5px;
    transition: 0.1s;
}

.tier-staff-icon:hover {
    font-size: 16px;
}

.tier-staff-checkbox {
    margin-top: 2px;
    margin-right: 30px;
}

.delete-tier-icon {
    color: $danger-color;
    font-size: 26px;
    cursor: pointer;
    opacity: .5;
    transition: 0.2s;
}

.delete-tier-icon:hover {
    opacity: 1;
}