.card-user-profile .description {
    font-weight: 400;
}

.consultant-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.consultant-info-container {
    align-self: center;
}

.consultant-info {
    display: inline-block;
}

.consultant-name {
    font-weight: 400;
    display: inline-block;
    margin: 0;
    font-size: 24px;
    margin-left: 5px;
}

@media screen and (max-width: 350px) {
    .consultant-container {
      display: block;
    }
    .consultant-button {
        width: 100%;
        margin-bottom: 0px!important;
    }
}