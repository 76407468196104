.spinner-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    z-index: 5000;
    align-items: center;
    justify-content: center;
}

.spinner-container {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 10px;
    height: 80px;
    width: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
}

.spinner {
    height: 100%;
    width: 100%;
    overflow: 'hidden';
    margin: 0 auto;
    outline: 'none';
}