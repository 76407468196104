.products-card-title {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    position: absolute;
    margin-top: 3px;
}

/* React table */
.products-table tr {
    padding: 8px 0px;
    border-bottom: 1px solid #ededed;
}

.products-table .rt-tbody .rt-tr {
    cursor: pointer;
}

.products-table .rt-thead .rt-tr {
    padding: 0px;
}
.product-name-cell-title {
    font-weight: 600;
}
.product-minimised-data {
    font-size: 13px;
}