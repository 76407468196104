.sweet-alert:focus {
  outline: none;
}
.sweet-alert {
  margin: auto !important;
}
.sweet-alert-custom-title {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}

.sweet-alert .lead {
  margin-top: 10px!important;
  font-weight: 400!important;
}