.booking-date-card {
    margin-bottom: 0px;
}

.booking-date-card > .card-body {
    padding: 0px;
}

.booking-date-card > .card-body > .form-group {
    margin: 0px;
}

.booking-date-card > .card-body > .form-group > .rdt > .form-control {
    border: none;
    font-size: 18px;
}

.booking-details-business-img {
    height: 80px!important;
}

.booking-options-dropdown {
    margin: 0 0 0 10px;
    height: 100%;
    background-color: #ffffff;
    color: #0a122b;
}

.booking-details-dropdown {
    display: none;
}

@media (max-width: 991px) {
    .booking-options-dropdown {
        display: none;
    }
    .booking-details-dropdown {
        display: block;
    }
}

.booking-details-avatar {
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;
    height: 80px;
    width: 80px;
    position: relative;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background-color: $primary-color;
    margin-bottom: 10px;
}

.booking-details-avatar-initials {
    line-height: 80px;
    color: #ffffff;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
}

.booking-details-pill {
    flex: 1;
    cursor: pointer;
}

.booking-details-pill > .nav-link {
    padding: 8px!important;
}

.delete-booking-service-icon {
    color: $danger-color;
    cursor: pointer;
    padding: 5px;
    opacity: .5;
    transition: 0.2s;
    position: absolute;
    font-size: 1.57em;
    right: 10px;
    top: 10px;
    z-index: 1;
}

.delete-booking-service-icon:hover {
    opacity: 1;
}

.total-container {
    padding: 20px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
}

.total-container-row {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.total-container {
    [class*="col-"] {
        .total-container-row::after {
            position: absolute;
            right: 0;
            width: 1px;
            height: 100%;
            content: "";
            background: #dbdbdb;
        }
    }
      [class*="col-"]:last-child {
        .total-container-row::after {
            display: none;
        }
    }
}

.load-customer-text {
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
}

.booking-time-input > input {
    height: 42px;
}

@media (max-width: 1199px) {
    .total-container-row {
        min-height: 50px;
    }
    .total-container {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.booking-timeline-modal {
    .modal-content {
        background-color: #e9e9e9;
    }
    .timeline li {
        margin-bottom: 0px;
    }
}